import { getBasicColor } from '@aurora/shared-client/helpers/styles/ColorVariableHelper';
import type { SafeColorCallback } from '@aurora/shared-client/helpers/styles/SafeColor';
import { ButtonFillStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import { CommonColorCssVariables } from '@aurora/shared-types/styles';

/**
 * This class, and classes that extend it, implement specific instructions for converting
 * Theme Editor results for Primary/Secondary/Tertiary/Destructive buttons into actual values to store into the Theme.
 */
class BaseBackgroundRenderHelper {
  constructor(protected safeColor: SafeColorCallback) {}

  /** Returns the default background color based on the button fill style */
  renderDefault(color: string, fillType: ButtonFillStyle) {
    return fillType === ButtonFillStyle.Outlined ? CommonColorCssVariables.TRANSPARENT : color;
  }

  /** Returns the hover background color based on the button fill style */
  renderHover(color: string, fillType: ButtonFillStyle) {
    const colorObject = getBasicColor(color, this.safeColor);

    if (fillType === ButtonFillStyle.Outlined) {
      return colorObject.alpha(0.15).toString();
    } else {
      if (colorObject.isDark()) {
        return colorObject.darken(0.15).toString();
      } else {
        return colorObject.darken(0.04).toString();
      }
    }
  }

  /** Returns the active background color based on the button fill style */
  renderActive(color: string, fillType: ButtonFillStyle): string {
    const colorObject = getBasicColor(color, this.safeColor);

    if (fillType === ButtonFillStyle.Outlined) {
      return colorObject.alpha(0.25).toString();
    } else {
      if (colorObject.isDark()) {
        return colorObject.darken(0.3).toString();
      } else {
        return colorObject.darken(0.08).toString();
      }
    }
  }
}

export default BaseBackgroundRenderHelper;
