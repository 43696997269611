import type { Range } from '@aurora/shared-types/range';
import isNullOrUndefined from '@aurora/shared-utils/helpers/objects/NullOrUndefinedHelper';

export function isWithinRange(value: number, range: Range) {
  const { min, max } = range ?? {};

  if (isNullOrUndefined(value)) {
    throw new Error('Invalid value');
  }

  if (isNullOrUndefined(min) || isNullOrUndefined(max)) {
    throw new Error('Malformed range');
  }

  return value >= min && value <= max;
}
