import isCssVariable, { isWrappedCssVariable, unwrapCssVariable } from './CssVariableHelper';
import type { SafeColorCallback } from './SafeColor';

interface BasicColor {
  /**
   * Lightens the color value.
   * @param ratio
   */
  lighten(ratio: number): string;
  /**
   * Darkkens the color value.
   * @param ratio
   */
  darken(ratio: number): string;
  /**
   * Makes the color value transparent by an amount.
   * @param ratio
   */
  alpha(ratio: number): string;
  /**
   * Checks if value is light.
   */
  isLight(): boolean;
  /**
   * Checks if value is dark.
   */
  isDark(): boolean;
}

/**
 * List of base CSS color variables that are supported.
 */
const baseColors = new Set([
  '--lia-bs-black',
  '--lia-bs-white',
  '--lia-bs-gray-100',
  '--lia-bs-gray-200',
  '--lia-bs-gray-300',
  '--lia-bs-gray-400',
  '--lia-bs-gray-500',
  '--lia-bs-gray-600',
  '--lia-bs-gray-700',
  '--lia-bs-gray-800',
  '--lia-bs-gray-900',
  '--lia-bs-dark',
  '--lia-bs-light',
  '--lia-bs-primary',
  '--lia-bs-secondary',
  '--lia-bs-body-bg',
  '--lia-bs-body-color',
  '--lia-bs-info',
  '--lia-bs-success',
  '--lia-bs-warning',
  '--lia-bs-danger',
  '--lia-alert-system-color',
  '--lia-bs-text-muted',
  '--lia-highlight-color'
]);

/**
 * Removes the '-h' from a CSS var.
 *
 * @param cssVariable
 * @returns
 */
function removeHFromCssVariable(cssVariable: string): string {
  if (cssVariable.endsWith('-h)')) {
    return cssVariable.replace('-h)', ')');
  }
  return cssVariable;
}

/**
 * Checks if a string is HSL using CSS color variable and if it is, extracts the main
 * CSS color variable (the H) used to make the HSL.
 *
 * @param color
 * @returns
 */
function getCssVariableWithinHsl(color: string): string {
  const match = color.match(/^(?:hsl|hsla)\(\s*(var\(([\d\s,a-z-]+)-h\))/);
  if (match) {
    // eslint-disable-next-line prefer-destructuring
    return match[2];
  }
  return color;
}

/**
 * Checks if color is special base CSS color variable.
 *
 * @param color
 * @returns
 */
function isBaseCssColorVariable(color: string): boolean {
  if (isWrappedCssVariable(color) || isCssVariable(color)) {
    return baseColors.has(unwrapCssVariable(color));
  }
  return false;
}

/**
 * Returns an HSLA using base CSS variables.
 *
 * @param colorVar
 * @param alpha
 * @returns
 */
function alphaBaseCssVariable(colorVar: string, alpha: number): string {
  const color = unwrapCssVariable(colorVar);

  return alpha === 1
    ? `var(${color})`
    : `hsla(var(${color}-h), var(${color}-s), var(${color}-l), ${alpha})`;
}

/**
 * Returns a darkened color using base CSS variables.
 *
 * @param colorVar
 * @param darken
 * @returns
 */
function darkenBaseCssVariable(colorVar: string, darken: number): string {
  const color = unwrapCssVariable(colorVar);
  const finalDarken = 1 - darken;

  return darken === 0
    ? `var(${color})`
    : `hsl(var(${color}-h), var(${color}-s), calc(var(${color}-l) * ${finalDarken}))`;
}

/**
 * Returns a lightened color using base CSS variables.
 *
 * @param colorVar
 * @param darken
 * @returns
 */
function lightenBaseCssVariable(colorVar: string, lighten: number): string {
  const color = unwrapCssVariable(colorVar);
  const finalLighten = 1 + lighten;

  return lighten === 0
    ? `var(${color})`
    : `hsl(var(${color}-h), var(${color}-s), calc(var(${color}-l) * ${finalLighten}))`;
}

/**
 * Returns a subset of the basic color functions that work with base CSS color
 * variables. Falls back to using safeColor.
 *
 * @param color
 * @param safeColorCallback
 * @returns
 */
export function getBasicColor(color: string, safeColorCallback: SafeColorCallback): BasicColor {
  const safeColor = safeColorCallback(color);
  const lightingFunctions: Pick<BasicColor, 'isDark' | 'isLight'> = {
    isDark: () => safeColor.isDark(),
    isLight: () => safeColor.isLight()
  };

  const finalColor = removeHFromCssVariable(getCssVariableWithinHsl(color));

  if (isBaseCssColorVariable(finalColor)) {
    return {
      ...lightingFunctions,
      alpha: ratio => alphaBaseCssVariable(finalColor, ratio),
      lighten: ratio => lightenBaseCssVariable(finalColor, ratio),
      darken: ratio => darkenBaseCssVariable(finalColor, ratio)
    };
  } else {
    return {
      ...lightingFunctions,
      alpha: ratio => safeColor.alpha(ratio).toString(),
      lighten: ratio => safeColor.lighten(ratio).toString(),
      darken: ratio => safeColor.darken(ratio).toString()
    };
  }
}
