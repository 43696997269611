import { toFontValue } from '@aurora/shared-client/helpers/styles/FontHelper';
import type { FontDefinition } from '@aurora/shared-client/helpers/fonts/FontDefinitions';
import FontDefinitions, {
  FontClassification,
  getStyle
} from '@aurora/shared-client/helpers/fonts/FontDefinitions';
import type ThemeEditorFontsResult from '@aurora/shared-client/components/community/ThemeEditorResult/ThemeEditorFontsResult';
import type ThemeResultMapper from './ThemeResultMapper';
import type { FontStyleDefinition } from '@aurora/shared-client/helpers/fonts/types';
import { getFontCustomValues } from '../../../helpers/community/ThemeEditorFontStyleHelper';
import type { ThemeResultFragment } from '@aurora/shared-generated/types/graphql-types';

/**
 * This could be considered a bit of a hack.
 * We should store the classification with the rest of the font metadata.
 */
function getClassification(
  customValues: FontDefinition[],
  family: string,
  style: FontStyleDefinition
) {
  let classification: FontClassification;

  if (!family) {
    throw new Error('Unknown font family, cannot derive classification');
  }

  const customVersion = customValues?.find(font => font.family === family);

  // This is where the hack comes in -- we assume we are using a custom font if the style in use exists on it.
  if (customVersion && customVersion.styles.includes(style)) {
    classification = FontClassification.CUSTOM;
  } else {
    const definition = FontDefinitions.fonts.find(font => font.family === family);

    // eslint-disable-next-line prefer-destructuring
    classification = definition.classification;
  }

  return classification;
}

class FontsMapper implements ThemeResultMapper<ThemeEditorFontsResult> {
  mapValues(theme: ThemeResultFragment): ThemeEditorFontsResult {
    const { coreTypes, heading, typography, rte } = theme;

    const customValues = getFontCustomValues(theme);
    const headingStyle = getStyle(heading.fontWeight, heading.fontStyle);
    const typographyStyle = getStyle(typography.fontWeightBase, typography.fontStyleBase);

    return {
      headings: {
        value: {
          classification: getClassification(customValues, heading.fontFamily, headingStyle),
          family: toFontValue(heading.fontFamily),
          style: headingStyle,
          heading1: heading.h1FontSize,
          heading2: heading.h2FontSize,
          heading3: heading.h3FontSize,
          heading4: heading.h4FontSize,
          heading5: heading.h5FontSize,
          heading6: heading.h6FontSize,
          lineHeight: heading.lineHeight,
          topSpacing: rte.defaultMessageHeaderMarginTop,
          bottomSpacing: rte.defaultMessageHeaderMarginBottom
        },
        ref: null
      },
      bodyText: {
        value: {
          classification: getClassification(
            customValues,
            typography.fontFamilyBase,
            typographyStyle
          ),
          family: toFontValue(typography.fontFamilyBase),
          style: typographyStyle,
          textDefault: typography.fontSizeBase,
          small: typography.fontSizeSm,
          xsmall: typography.fontSizeXs,
          subheading: heading.subHeaderFontSize,
          lineHeight: typography.lineHeightBase,
          bottomSpacing: rte.defaultMessageItemMarginBottom
        },
        ref: null
      },
      overrides: {
        value: {
          discussions: !!coreTypes.forumFontStyle,
          kbArticles: !!coreTypes.tkbFontStyle,
          blogPosts: !!coreTypes.blogFontStyle,
          events: !!coreTypes.occasionFontStyle,
          ideas: !!coreTypes.ideaFontStyle
        },
        ref: null
      },
      custom: customValues
    } as ThemeEditorFontsResult;
  }
}

export default FontsMapper;
