import type { Asset } from '@aurora/shared-generated/types/graphql-schema-types';
import type ComponentShape from '../../../helpers/components/ComponentShape';

export enum AvatarCollectionId {
  DEFAULT = 'default',
  CUSTOM = 'custom'
}

export interface UploadedAvatar {
  /**
   * Avatar asset name.
   */
  name: string;
  /**
   * Avatar asset url.
   */
  url: string;
  /**
   * The file to upload on Save and Publish action
   */
  file: File;
}

interface ThemeEditorAvatarsResult {
  /**
   * The shape the rendered avatars should take on.
   */
  visualStyle: ComponentShape;

  /**
   * The border-radius associated with the selected visualStyle -- can be hardcoded for circle or square, or within a range for rounded
   */
  borderRadius: string;

  /**
   * Whether to include our pre-packaged avatars.
   */
  includeDefaultAvatarSet: boolean;

  /**
   * List of avatars collection ids used by the theme.
   */
  collections: AvatarCollectionId[];

  /**
   * List of custom avatars that have been deleted during the current session.
   */
  deletedAvatars: Pick<Asset, 'name' | 'url'>[];

  /**
   * List of custom avatars that have been uploaded during the current session.
   */
  uploadedAvatars: UploadedAvatar[];
}

export default ThemeEditorAvatarsResult;
