import { AvatarCollectionId } from '@aurora/shared-client/components/community/ThemeEditorResult/ThemeEditorAvatarsResult';
import type ComponentShape from '@aurora/shared-client/helpers/components/ComponentShape';
import type ThemeEditorAvatarsResult from '@aurora/shared-client/components/community/ThemeEditorResult/ThemeEditorAvatarsResult';
import type ThemeResultMapper from './ThemeResultMapper';
import type { ThemeResultFragment } from '@aurora/shared-generated/types/graphql-types';
import { getVisualStyle } from '../../../helpers/community/ThemeEditorAvatarsHelper';

class AvatarsMapper implements ThemeResultMapper<ThemeEditorAvatarsResult> {
  mapValues(theme: ThemeResultFragment): ThemeEditorAvatarsResult {
    const { avatar } = theme;

    const visualStyle: ComponentShape = getVisualStyle(theme);

    return {
      borderRadius: avatar.borderRadius,
      includeDefaultAvatarSet: avatar.collections
        ? avatar.collections.includes(AvatarCollectionId.DEFAULT)
        : false,
      visualStyle,
      collections: avatar.collections ?? [],
      deletedAvatars: [],
      uploadedAvatars: []
    } as ThemeEditorAvatarsResult;
  }
}

export default AvatarsMapper;
