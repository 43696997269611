import ThemeStyleguideColorsCategory from '../ThemeStyleguideColorsCategory/ThemeStyleguideColorsCategory';
import type ThemeStyleguideColorsSection from '@aurora/shared-client/components/community/ThemeStyleguideColorsSection/ThemeStyleguideColorsSection';
import type ThemeResultMapper from './ThemeResultMapper';
import type { ThemeResultFragment } from '@aurora/shared-generated/types/graphql-types';
import type { PrimaryCommunityColorsSection } from '@aurora/shared-client/components/community/ThemeStyleguideColorsSections/ThemeStyleguideColorsSections';
import type ThemeStyleguideColorsSections from '@aurora/shared-client/components/community/ThemeStyleguideColorsSections/ThemeStyleguideColorsSections';
import type { Tenant } from '@aurora/shared-types/tenant';

class ColorsMapper implements ThemeResultMapper<ThemeStyleguideColorsSections> {
  mapValues(theme: ThemeResultFragment, tenant: Tenant): ThemeStyleguideColorsSections {
    const { ideasEnabled: useIdeas, occasionsEnabled: useOccasions } = tenant?.publicConfig;

    const { colors, coreTypes } = theme;

    const primaryCommunity: PrimaryCommunityColorsSection = {
      displayKey: ThemeStyleguideColorsCategory.PRIMARY_COMMUNITY,
      value: {
        primaryAccent: colors.primary,
        primaryText: colors.bodyText,
        pageBackground: colors.bodyBg
      }
    };

    const additionalColorsValue: Record<string, string> = {};
    colors.custom.forEach((customColor, i) => {
      const key = `custom-${i}`;
      additionalColorsValue[key] = customColor;
    });

    const additionalColors: ThemeStyleguideColorsSection = {
      displayKey: ThemeStyleguideColorsCategory.ADDITIONAL_COLORS,
      value: additionalColorsValue
    };

    const contentTypeIcons: ThemeStyleguideColorsSection = {
      displayKey: ThemeStyleguideColorsCategory.CONTENT_TYPE_ICONS,
      value: {
        discussion: coreTypes.forumColor,
        discussionSolved: coreTypes.forumSolvedColor,
        knowledgeBase: coreTypes.tkbColor,
        blog: coreTypes.blogColor
      }
    };
    if (useIdeas) {
      contentTypeIcons.value['idea'] = coreTypes.ideaColor;
    }
    if (useOccasions) {
      contentTypeIcons.value['occasion'] = coreTypes.occasionColor;
    }
    contentTypeIcons.value['groupHub'] = coreTypes.grouphubColor;

    const alertMessages: ThemeStyleguideColorsSection = {
      displayKey: ThemeStyleguideColorsCategory.ALERT_MESSAGES,
      value: {
        information: colors.info,
        success: colors.success,
        warning: colors.warning,
        danger: colors.danger
      }
    };

    const grayscale: ThemeStyleguideColorsSection = {
      value: {
        black: colors.black,
        gray900: colors.gray900,
        gray800: colors.gray800,
        gray700: colors.gray700,
        gray600: colors.gray600,
        gray500: colors.gray500,
        gray400: colors.gray400,
        gray300: colors.gray300,
        gray200: colors.gray200,
        gray100: colors.gray100,
        white: colors.white
      }
    };

    return {
      primaryCommunity,
      additionalColors,
      contentTypeIcons,
      alertMessages,
      grayscale
    } as ThemeStyleguideColorsSections;
  }
}

export default ColorsMapper;
