import ComponentShape, {
  ComponentShapeOptions
} from '@aurora/shared-client/helpers/components/ComponentShape';
import type { ThemeResultFragment } from '@aurora/shared-generated/types/graphql-types';

/**
 * Determines the selected option for Avatars -> Visual Style
 * @param theme The theme, as loaded from the server
 * @returns a {@link ComponentShape} value
 */
function getVisualStyle(theme: ThemeResultFragment): ComponentShape {
  const { avatar } = theme;

  if (avatar.borderRadius === ComponentShapeOptions.SQUARE_BORDER_RADIUS) {
    return ComponentShape.SQUARE;
  } else if (avatar.borderRadius === ComponentShapeOptions.CIRCLE_BORDER_RADIUS) {
    return ComponentShape.CIRCLE;
  } else {
    /**
     * While in most cases we return `null` when a custom value is present,
     * any valid custom value for this field will still result in a 'square rounded' avatar
     * We'll keep it selected, and do further checks in the form to determine
     * whether to display custom value warnings.
     */
    return ComponentShape.SQUARE_ROUNDED;
  }
}

export { getVisualStyle };
