import { BackgroundExtension } from '@aurora/shared-generated/types/graphql-schema-types';
import type ThemeEditorBasicsResult from '@aurora/shared-client/components/community/ThemeEditorResult/ThemeEditorBasicsResult';
import type ThemeResultMapper from './ThemeResultMapper';
import type { ThemeResultFragment } from '@aurora/shared-generated/types/graphql-types';

class BasicsMapper implements ThemeResultMapper<ThemeEditorBasicsResult> {
  mapValues(theme: ThemeResultFragment): ThemeEditorBasicsResult {
    const { basics } = theme;

    return {
      backgroundExtendsTo: basics.pageWidthStyle ?? BackgroundExtension.WidthOfBrowser,
      pageContentWidth: basics.maximumWidthOfPageContent,
      customerLogo: basics.customerLogo,
      browserIcon: basics.browserIcon
    };
  }
}

export default BasicsMapper;
