import type { ThemeResultFragment } from '@aurora/shared-generated/types/graphql-types';
import type ThemeResultMapper from './ThemeResultMapper';

class IdMapper implements ThemeResultMapper<string> {
  mapValues(theme: ThemeResultFragment): string {
    const { id } = theme;

    return id === 'aurora' ? null : id;
  }
}

export default IdMapper;
