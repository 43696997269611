import { getBasicColor } from '@aurora/shared-client/helpers/styles/ColorVariableHelper';
import BaseBackgroundRenderHelper from './BaseBackgroundRenderHelper';
import { CommonColorCssVariables } from '@aurora/shared-types/styles';

class TertiaryBackgroundRenderHelper extends BaseBackgroundRenderHelper {
  renderActive(color: string): string {
    const colorObject = getBasicColor(color, this.safeColor);
    return colorObject.alpha(0.04).toString();
  }

  renderDefault(): string {
    return CommonColorCssVariables.TRANSPARENT;
  }

  renderHover(): string {
    return CommonColorCssVariables.TRANSPARENT;
  }
}

export default TertiaryBackgroundRenderHelper;
