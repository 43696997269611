import {
  getBorder,
  getBorderRadius,
  getInputShape,
  getModalOverlay,
  getShadowStyle
} from '../../../helpers/community/ThemeEditorElementsHelper';
import type ThemeEditorElementsResult from '@aurora/shared-client/components/community/ThemeEditorResult/ThemeEditorElementResult';
import type ThemeResultMapper from './ThemeResultMapper';
import type { ThemeResultFragment } from '@aurora/shared-generated/types/graphql-types';

class ElementsMapper implements ThemeResultMapper<ThemeEditorElementsResult> {
  mapValues(theme: ThemeResultFragment): ThemeEditorElementsResult {
    return {
      borderRadius: getBorderRadius(theme),
      mainContentBorder: getBorder(theme?.border?.mainContent),
      sideContentBorder: getBorder(theme?.border?.sideContent),
      shadows: getShadowStyle(theme),
      inputShape: getInputShape(theme),
      modalOverlay: getModalOverlay(theme)
    };
  }
}

export default ElementsMapper;
