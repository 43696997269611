import { FontStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import type ThemeEditorButtonResult from '@aurora/shared-client/components/community/ThemeEditorResult/ThemeEditorButtonsResult';
import type { ButtonShape } from '@aurora/shared-client/helpers/styles/ButtonStyleHelper';
import type ThemeResultMapper from './ThemeResultMapper';
import type { ThemeResultFragment } from '@aurora/shared-generated/types/graphql-types';
import { getAsEnum } from '@aurora/shared-utils/helpers/objects/EnumHelper';
import { getStyle } from '@aurora/shared-client/helpers/fonts/FontDefinitions';
import safeColor from '@aurora/shared-client/helpers/styles/SafeColor';
import ThemeEditorButtonsHelper from '../../../helpers/community/ThemeEditorButtonsHelper';
import type { ColorCssVariableOrValue } from '@aurora/shared-types/styles';
import { getCssRulesForTheme } from '@aurora/shared-client/helpers/styles/ThemeRulesHelper';

class ButtonsMapper implements ThemeResultMapper<ThemeEditorButtonResult> {
  mapValues(theme: ThemeResultFragment): ThemeEditorButtonResult {
    const variableMap = getCssRulesForTheme(theme);
    const buttonHelper = new ThemeEditorButtonsHelper((color: ColorCssVariableOrValue) => {
      return safeColor(variableMap, color);
    });

    const { buttons, coreTypes } = theme;

    const buttonShape: ButtonShape = buttonHelper.getButtonShape(theme);

    const linkFontStyle = getAsEnum(
      coreTypes.defaultMessageLinkFontStyle,
      FontStyle,
      FontStyle.Normal
    );

    const linkFontStyleName = getStyle(coreTypes.defaultMessageLinkFontWeight, linkFontStyle).name;

    const buttonFontStyle = getAsEnum(buttons.fontStyle, FontStyle, FontStyle.Normal);
    const buttonFontStyleName = getStyle(buttons.fontWeight, buttonFontStyle).name;

    const {
      borderRadius,
      borderRadiusLg,
      paddingX,
      paddingXLg,
      paddingXHero,
      primaryTextColor,
      primaryBgColor,
      primaryBorder,
      secondaryTextColor,
      secondaryBgColor,
      secondaryBorder,
      tertiaryTextColor,
      tertiaryBorderHover,
      destructiveTextColor,
      destructiveBgColor,
      destructiveBorder
    } = buttons;

    const { defaultMessageLinkColor: linkColor } = coreTypes;

    return {
      visualStyle: buttonHelper.getFillStyle(theme),
      buttonFontStyleName,
      buttonShape,
      borderRadius,
      borderRadiusLg,
      paddingX,
      paddingXLg,
      paddingXHero,
      primaryTextColor,
      primaryBaseColor: buttonHelper.getBaseColorForButton(primaryBgColor, primaryBorder),
      secondaryTextColor,
      secondaryBaseColor: buttonHelper.getBaseColorForButton(secondaryBgColor, secondaryBorder),
      tertiaryTextColor,
      tertiaryBaseColor: buttonHelper.getBaseColorFromBorderColor(tertiaryBorderHover),
      destructiveTextColor,
      destructiveBaseColor: buttonHelper.getBaseColorForButton(
        destructiveBgColor,
        destructiveBorder
      ),
      linkColor,
      linkFontStyleName
    };
  }
}

export default ButtonsMapper;
