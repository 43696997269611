import { ContentBorder } from '@aurora/shared-generated/types/graphql-schema-types';
import type { ThemeResultFragment } from '@aurora/shared-generated/types/graphql-types';
import {
  ElementsBorderRadius,
  ElementsBorderShadow,
  ElementsInputShape,
  ElementsModalOverlay
} from '@aurora/shared-client/components/community/ThemeEditorResult/ThemeEditorElementResult';
import {
  BorderRadiusOptions,
  BorderRadiusSmOptions,
  BorderRadiusLgOptions,
  BorderShadowOptions,
  ElementsConstants,
  InputShapeCheckboxOptions,
  InputShapeLgOptions,
  InputShapeOptions,
  InputShapeSmOptions,
  InputShapeTextAreaOptions,
  BorderShadowSmOptions,
  BorderShadowLgOptions,
  BorderShadowXsOptions
} from './ThemeEditorElementsRulesHelper';

/**
 * Determines the selected option for UI Elements -> Borders - main/side content
 * @param theme The theme, as loaded from the server
 * @returns the same value that was passed in, if possible. Otherwise, a default value.
 */
function getBorder(border: ContentBorder): ContentBorder {
  return border ?? ContentBorder.None;
}

/**
 * Determines the selected option for UI Elements -> Border corner radius
 * @param theme The theme, as loaded from the server
 * @returns an {@link ElementsBorderRadius} value if all values align with expected Theme Editor values, or null if any custom value if present.
 */
function getBorderRadius(theme: ThemeResultFragment): ElementsBorderRadius {
  const { border } = theme;

  if (
    border.radius === BorderRadiusOptions.large &&
    border.radiusSm === BorderRadiusSmOptions.large &&
    border.radiusLg === BorderRadiusLgOptions.large
  ) {
    return ElementsBorderRadius.LARGE;
  } else if (
    border.radius === BorderRadiusOptions.small &&
    border.radiusSm === BorderRadiusSmOptions.small &&
    border.radiusLg === BorderRadiusLgOptions.small
  ) {
    return ElementsBorderRadius.SMALL;
  } else if (
    border.radius === BorderRadiusOptions.none &&
    border.radiusSm === BorderRadiusSmOptions.none &&
    border.radiusLg === BorderRadiusLgOptions.none
  ) {
    return ElementsBorderRadius.NONE;
  } else {
    return null;
  }
}

/**
 * Determines the selected option for UI Elements -> Form inputs
 * @param theme The theme, as loaded from the server
 * @returns an {@link ElementsInputShape} value if all values align with expected Theme Editor values, or null if any custom value is present.
 */
function getInputShape(theme: ThemeResultFragment): ElementsInputShape {
  const { input } = theme;

  if (
    input.borderRadius === InputShapeOptions.pill &&
    input.borderRadiusLg === InputShapeLgOptions.pill &&
    input.borderRadiusSm === InputShapeSmOptions.pill &&
    input.checkboxBorderRadius === InputShapeCheckboxOptions.pill &&
    input.textAreaBorderRadius === InputShapeTextAreaOptions.pill
  ) {
    return ElementsInputShape.PILL;
  } else if (
    input.borderRadius === InputShapeOptions.rounded &&
    input.borderRadiusLg === InputShapeLgOptions.rounded &&
    input.borderRadiusSm === InputShapeSmOptions.rounded &&
    input.checkboxBorderRadius === InputShapeCheckboxOptions.rounded &&
    input.textAreaBorderRadius === InputShapeTextAreaOptions.rounded
  ) {
    return ElementsInputShape.ROUNDED;
  } else if (
    input.borderRadius === InputShapeOptions.square &&
    input.borderRadiusLg === InputShapeLgOptions.square &&
    input.borderRadiusSm === InputShapeSmOptions.square &&
    input.checkboxBorderRadius === InputShapeCheckboxOptions.square &&
    input.textAreaBorderRadius === InputShapeCheckboxOptions.square
  ) {
    return ElementsInputShape.SQUARE;
  } else {
    return null;
  }
}

/**
 * Determines the selected option for UI Elements -> Modal overlays
 * @param theme The theme, as loaded from the server
 * @returns an {@link ElementsModalOverlay} value if the saved value aligns with the expected Theme Editor values, or the saved (custom) value if not
 */
function getModalOverlay(theme: ThemeResultFragment): ElementsModalOverlay | string {
  const { modal } = theme;

  if (modal.backgroundBg === ElementsConstants.Black) {
    return ElementsModalOverlay.DARK;
  } else if (modal.backgroundBg === ElementsConstants.White) {
    return ElementsModalOverlay.LIGHT;
  } else {
    return modal.backgroundBg;
  }
}

/**
 * Determines the selected option for UI Elements -> Shadows
 * @param theme The theme, as loaded from the server
 * @returns an {@link ElementsBorderShadow} value if the saved values align with the expected Theme Editor values, or null if any custom value is present.
 */
function getShadowStyle(theme: ThemeResultFragment): ElementsBorderShadow {
  const { boxShadow } = theme;

  if (
    boxShadow.xs === BorderShadowXsOptions.dark &&
    boxShadow.sm === BorderShadowSmOptions.dark &&
    boxShadow.md === BorderShadowOptions.dark &&
    boxShadow.lg === BorderShadowLgOptions.dark
  ) {
    return ElementsBorderShadow.DARK;
  } else if (
    boxShadow.xs === BorderShadowXsOptions.light &&
    boxShadow.sm === BorderShadowSmOptions.light &&
    boxShadow.md === BorderShadowOptions.light &&
    boxShadow.lg === BorderShadowLgOptions.light
  ) {
    return ElementsBorderShadow.LIGHT;
  } else if (
    boxShadow.xs === BorderShadowXsOptions.none &&
    boxShadow.sm === BorderShadowSmOptions.none &&
    boxShadow.md === BorderShadowOptions.none &&
    boxShadow.lg === BorderShadowLgOptions.none
  ) {
    return ElementsBorderShadow.NONE;
  } else {
    return null;
  }
}

export { getBorder, getBorderRadius, getInputShape, getModalOverlay, getShadowStyle };
