import type { SafeColorCallback } from '@aurora/shared-client/helpers/styles/SafeColor';
import type { ValidColorCheck } from '@aurora/shared-client/helpers/styles/ColorHelper';
import { parseColorFromCssBorder } from '@aurora/shared-client/helpers/styles/ColorHelper';

import { getBasicColor } from '@aurora/shared-client/helpers/styles/ColorVariableHelper';
import {
  ButtonShape,
  ButtonShapeOptions
} from '@aurora/shared-client/helpers/styles/ButtonStyleHelper';
import type { ThemeResultFragment } from '@aurora/shared-generated/types/graphql-types';
import type { Range } from '@aurora/shared-types/range';
import { isPixel } from '../util/PixelHelper';
import { isWithinRange } from '@aurora/shared-client/helpers/range/RangeHelper';
import { CommonColorCssVariables } from '@aurora/shared-types/styles';
import { ButtonFillStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import TertiaryBackgroundRenderHelper from './ThemeEditorButtonsRenderHelpers/background/TertiaryBackgroundRenderHelper';

export const borderRadiusRange: Range = {
  min: 1,
  max: 10
};

const borderRadiusSmRange: Range = borderRadiusRange;

export const borderRadiusLgRange: Range = {
  min: 1,
  max: 12
};

export default class ThemeEditorButtonsHelper {
  private readonly safeColor: SafeColorCallback;

  private readonly validColorCheck: ValidColorCheck;

  constructor(safeColor: SafeColorCallback) {
    this.safeColor = safeColor;
    this.validColorCheck = color => this.safeColor(color) !== null;
  }

  /**
   * By default, and when using the Theme Editor UI,
   * the border radii for normal and small buttons will match.
   * If they do not, that implies that the Theme Editor API has been used to save a custom value.
   * @param theme The theme as loaded from the server
   */
  doesBorderRadiusSmMatchBorderRadius(theme: ThemeResultFragment): boolean {
    const { borderRadiusSm, borderRadius } = theme.buttons;

    return borderRadiusSm === borderRadius;
  }

  /**
   * Derive the {@link ButtonShape} from the specified border radii
   * @param theme the theme as loaded from the server
   */
  getButtonShape(theme: ThemeResultFragment): ButtonShape {
    const { borderRadius, borderRadiusSm, borderRadiusLg } = theme.buttons;

    if (
      borderRadius === ButtonShapeOptions.SQUARE_BORDER_RADIUS &&
      borderRadiusSm === ButtonShapeOptions.SQUARE_BORDER_RADIUS &&
      borderRadiusLg === ButtonShapeOptions.SQUARE_BORDER_RADIUS
    ) {
      return ButtonShape.SQUARE;
    } else if (
      borderRadius === ButtonShapeOptions.PILL_BORDER_RADIUS &&
      borderRadiusSm === ButtonShapeOptions.PILL_BORDER_RADIUS &&
      borderRadiusLg === ButtonShapeOptions.PILL_BORDER_RADIUS
    ) {
      return ButtonShape.PILL;
    } else if (
      this.doesBorderRadiusSmMatchBorderRadius(theme) &&
      isPixel(borderRadius) &&
      isWithinRange(Number.parseInt(borderRadius, 10), borderRadiusRange) &&
      isPixel(borderRadiusSm) &&
      isWithinRange(Number.parseInt(borderRadiusSm, 10), borderRadiusSmRange) &&
      isPixel(borderRadiusLg) &&
      isWithinRange(Number.parseInt(borderRadiusLg, 10), borderRadiusLgRange)
    ) {
      return ButtonShape.ROUNDED;
    } else {
      return null;
    }
  }

  /**
   * Derive the base color of button from the specified border color
   * @param borderColor the border color
   */
  getBaseColorFromBorderColor(borderColor: string): string {
    const color = parseColorFromCssBorder(borderColor, borderColor, this.validColorCheck);
    const colorObject = getBasicColor(color, this.safeColor);

    return colorObject.alpha(1).toString();
  }

  /**
   * Derive base color of button based on fill type, background color, and border color.
   *
   * @param backgroundColor the background color
   * @param borderColor the border color
   * @param fillType the fill type
   */
  getBaseColorForButton(backgroundColor: string, borderColor: string): string {
    const isFilled = backgroundColor !== CommonColorCssVariables.TRANSPARENT;

    if (isFilled) {
      return backgroundColor;
    } else {
      return this.getBaseColorFromBorderColor(borderColor);
    }
  }

  /** Determines the pre-selected fill style based on the input theme */
  getFillStyle(theme: ThemeResultFragment): ButtonFillStyle | null {
    const { buttons } = theme;
    const { primaryBgColor, secondaryBgColor, tertiaryBgColor, destructiveBgColor } = buttons;

    // Don't include tertiary here, it is a special case
    const bgColors = [primaryBgColor, secondaryBgColor, destructiveBgColor];
    const tertiaryButtonHelper = new TertiaryBackgroundRenderHelper(this.safeColor);
    const isTertiaryCustom = tertiaryBgColor !== tertiaryButtonHelper.renderDefault();
    let fillStyle: ButtonFillStyle;

    if (
      bgColors.every(color => color === CommonColorCssVariables.TRANSPARENT) &&
      !isTertiaryCustom
    ) {
      fillStyle = ButtonFillStyle.Outlined;
    } else if (
      bgColors.every(color => color !== CommonColorCssVariables.TRANSPARENT) &&
      !isTertiaryCustom
    ) {
      fillStyle = ButtonFillStyle.Filled;
    } else {
      fillStyle = null;
    }

    return fillStyle;
  }
}
