import type { ThemeResultFragment } from '@aurora/shared-generated/types/graphql-types';
import type { ThemeEditorResult } from '@aurora/shared-client/components/community/ThemeEditorResult/ThemeEditorResult';
import AvatarsMapper from './AvatarsMapper';
import BasicsMapper from './BasicsMapper';
import ButtonsMapper from './ButtonsMapper';
import ColorsMapper from './ColorsMapper';
import ElementsMapper from './ElementsMapper';
import FontsMapper from './FontsMapper';
import IdMapper from './IdMapper';
import type { Tenant } from '@aurora/shared-types/tenant';

export default function fromQuery(theme: ThemeResultFragment, tenant: Tenant): ThemeEditorResult {
  return {
    id: new IdMapper().mapValues(theme),
    basics: new BasicsMapper().mapValues(theme),
    avatars: new AvatarsMapper().mapValues(theme),
    colors: new ColorsMapper().mapValues(theme, tenant),
    buttons: new ButtonsMapper().mapValues(theme),
    fonts: new FontsMapper().mapValues(theme),
    elements: new ElementsMapper().mapValues(theme)
  };
}
