/**
 * Options for component shape
 */
enum ComponentShape {
  CIRCLE = 'circle',
  SQUARE_ROUNDED = 'squareRounded',
  SQUARE = 'square'
}

export const ComponentShapeOptions = {
  SQUARE_BORDER_RADIUS: '0',
  CIRCLE_BORDER_RADIUS: '50%'
};

export default ComponentShape;
